<template>
  <div class="login-container">
    <div class="login-pane">
      <i class="el-icon-loading" />
      <h1>安全校验中，请稍等</h1>
    </div>
  </div>
</template>

<script>
import loginApi from '@/service/api/login/login-api'
import authorization from '@/store/utils/authorization'

export default {
  name: 'Login',
  data () {
    return {
    }
  },
  mounted() {
    this.login()
  },
  methods: {
    login() {
      // 获取URI上的票据
      const ticket = window.location.search
      if (ticket) {
        // 有票据则通过有票据登录
        this.doLoginByTicket(ticket.substring(8, ticket.length))
      } else {
        // 无票据则跳转获取票据
        this.goSsoAuthUrl()
      }
    },
    // 根据ticket值登录
    doLoginByTicket(ticket) {
      loginApi.login({ ticket: ticket }).then(res => {
        if (res.data.status === 200) {
          authorization.handleLogin(res.data.data)
        } else {
          authorization.setToken('failAuth')
          this.$emit('close', true)
          this.$notify({ title: '提示', message: res.data.msg || '票据登录失败！', offset: 100 })
        }
      }).catch(err => {
        console.log(err)
        this.$notify.error({ title: '失败', message: '票据登录失败！', offset: 100 })
        authorization.setToken('failAuth')
        this.$router.replace('/index')
      })
    },
    goSsoAuthUrl() {
      loginApi.queryT({ clientLoginUrl: location.href }).then(res => {
        if (res.data.status === 200) {
          const a = document.createElement('a')
          a.href = res.data.data
          a.click()
          a.remove()
        } else {
          authorization.setToken('failAuth')
          this.$notify.error({ title: '失败', message: res.data.msg || '请求获取票据失败！', offset: 100 })
        }
      }).catch(e => {
        console.log(e)
        authorization.setToken('failAuth')
        this.$notify.error({ title: '失败', message: '请求获取票据失败！', offset: 100 })
        this.$router.replace('/index')
      })
    }
  }
}
</script>

<style scoped lang="scss">
.login-container{
  height: 100vh;
  width: 100%;
  display:flex;
  justify-content: center;
  align-items: center;
  background-color: #888888;
  .login-pane{
    width: 280px;
    height: 140px;
    background-color: #ffffff;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    i{
      font-size: 48px;
      color: #409eff;
    }
    h1{
      font-size: 20px;
    }
  }
}
</style>
